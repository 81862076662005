import { createContext, useEffect, useState } from "react";
import { useReducer } from "react";
import { dappReducer, dappInitialState } from './reducer/DappReducer';
import Mint from "./components/Mint";
import Manage from "./components/Manage";
import Vault from "./components/Vault";
import Wallet from "./components/Wallet";
import PopupTx from "./components/PopupTx";

const client = require('./client.json');
const Lib = require('./Lib');
const Dapp = require("./contracts/Dapp");
const { CHAIN_ID, PROVIDER_URL, blockchainName, metamaskUrl, chainlistUrl } = client;

export const DappContext = createContext();

function Dashboard() {
  const [dappState, dappDispatch] = useReducer(dappReducer, dappInitialState);
  const [selTab, setSelTab] = useState(0);

  useEffect(() => {
    const DAPP = new Dapp(CHAIN_ID);
    let itv;

    const updateTS = async () => {
      try {
        const ts = await DAPP.getBlockTS();
        dappDispatch({ type: 'SET_TS', ts: ts });
        // if (dappState.ts !== ts) {
        //   dappDispatch({ type: 'SET_TS', ts: ts });
        // }
      } catch (err) {
      }
    }

    const initDapp = async () => {
      let walletOK = false;
      try {
        await DAPP.detectMetamask();
        await DAPP.loadMetamask();
        walletOK = true;
      } catch (err) {
        // console.error(err);
        console.error('** fail to load metamask **');
      }

      try {
        if (!walletOK) await DAPP.loadPrivateKey(null, PROVIDER_URL);
        await DAPP.initContracts();
        const userData = await DAPP.getUserData();
        const userNftData = await DAPP.NFT.getUserNft();
        const farmData = await DAPP.VAULT.getUserData();

        dappDispatch({ type: 'SET_DAPP', dapp: DAPP, userData, userNftData });
        dappDispatch({ type: 'SET_FARM_DATA', farmData });

        await updateTS();
        let busy = false;
        itv = setInterval(async () => {
          if (!busy) {
            busy = true;
            await updateTS();
            busy = false;
          }
        }, 10000);

      } catch (err) {
        // console.error(err);
        console.error('** fail to initialize contract **');
      }
    }

    const cleanUpDapp = async () => {
      await DAPP.cleanUp();
      if (itv) clearInterval(itv);
    }

    const qp = window.location.href;
    if (qp.indexOf('mint') >= 0) setSelTab(0);
    else if (qp.indexOf('manage') >= 0) setSelTab(1);
    else if (qp.indexOf('vault') >= 0) setSelTab(2);

    initDapp();
    return () => {
      cleanUpDapp();
    }

  }, []);

  const header = (
    <Wallet />
  );

  const content1 = (
    <Mint />
  );

  const content2 = (
    <Manage />
  );

  const content3 = (
    <Vault />
  );

  const popupMetamask = (
    <dialog id="modal_metamask" className="modal modal-bottom sm:modal-middle">
      <div className="modal-box">
        <h3 className="font-bold text-xl text-center">Connect to {blockchainName}</h3>
        <div className="flex flex-col gap-2 mt-4">
          <button className="btn" onClick={() => Lib.openUrl(metamaskUrl)}>Install Metamask</button>
          <button className="btn" onClick={() => Lib.openUrl(chainlistUrl)}>Setup Metamask To {blockchainName}</button>
          <button className="btn" onClick={() => Lib.refreshPage()}>Refresh Page</button>
        </div>
      </div>
    </dialog>
  );

  return (
    <DappContext.Provider value={{ dappState, dappDispatch }}>
      <div className="min-h-screen flex  bg-base-200 justify-center md:p-4 lg:p-8">
        <div className="flex flex-grow flex-col max-w-4xl gap-4">
          <div className="card bg-base-100 shadow-xl w-full min-h-screen md:min-h-fit">
            <div className="card-body">
              {header}
              <div role="tablist" className="tabs tabs-lifted">
                <input type="radio"
                  checked={selTab === 0}
                  onChange={e => setSelTab(0)}
                  role="tab" className="tab" aria-label="Mint" />
                <div role="tabpanel" className="tab-content bg-base-100 border-base-300 rounded-box p-2 md:p-4">
                  {content1}
                </div>

                <input type="radio"
                  checked={selTab === 1}
                  onChange={e => setSelTab(1)}
                  role="tab" className="tab" aria-label="Manage" />
                <div role="tabpanel" className="tab-content bg-base-100 border-base-300 rounded-box p-2 md:p-4">
                  {content2}
                </div>

                <input type="radio"
                  checked={selTab === 2}
                  onChange={e => setSelTab(2)}
                  role="tab" className="tab" aria-label="Vault" />
                <div role="tabpanel" className="tab-content bg-base-100 border-base-300 rounded-box p-2 md:p-4">
                  {content3}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      {popupMetamask}
      <PopupTx />
    </DappContext.Provider>
  );
}

export default Dashboard;
